.table_walkin {
    border: 1px solid #DCDCDC;
    border-radius: 24px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    overflow: visible !important;

}

.walkin_table_custom .table-responsive {
    overflow: visible !important;
}

.table td {
    border: none;
}


.hover-button:hover {
    background-color: #1E45E1 !important;
    color: white !important;
}