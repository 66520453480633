.table-booking{
    border: 1px solid #DCDCDC;
    border-radius: 16px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    overflow: visible !important;
    
   }
   .booking-table-userlist .table-responsive{
    overflow: visible !important;
   }
  
   .table td{
    border: none;
   }



  .hover-button:hover {
    background-color: #1E45E1 !important;
    color: white !important;
}
  