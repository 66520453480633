.responsive-button {
    width: auto;
  }
  .custom-modal .modal-content {
    border-radius: 20px;
    /* width:400px;
    padding: 10px; */
  }
  
  @media (max-width: 768px) {
    .responsive-button {
      font-size: 12px;
      padding: 12px 20px; 
    }
  }
  
  @media (max-width: 750px) {
    .responsive-button {
      font-size: 10px; 
      padding: 8px 16px; 
      width: 100%;
      margin-top: 40px;
    }
    .card{
        margin-top: 40px;
    }
  }
  