 #vendor-select{
    border:1px solid #D9D9D9 !important;
    font-size: 16px; 
        color: #4B4B4B;
         font-family:Gilroy,sans-serif;
          /* font-weight: 500; */
           box-shadow: "none";
         height: 50px;
        border-radius: 8px;
}
.custom-modal .modal-content {
  border-radius: 8px !important;
  
}

#AddAsset{
  border-radius: 8;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  /* background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius); */
  outline: 0;
}

.custom-date-input::-webkit-calendar-picker-indicator {
  filter: invert(22%) sepia(82%) saturate(2607%) hue-rotate(192deg) brightness(96%) contrast(101%);
}

.custom-date-input::-webkit-datetime-edit {
  color: #4B4B4B; 
}
/* .table-responsive{
  overflow: unset !important;
} */

#vendor-select-pg{
  border-left:1px solid #D9D9D9 !important;
  border-right: unset !important;
  border-top: 1px solid #D9D9D9 !important;
  border-bottom: 1px solid #D9D9D9 !important;
  font-size: 16px; 
      color: #4B4B4B;
       font-family:Gilroy,sans-serif;
        /* font-weight: 500; */
         box-shadow: "none";
       height: 50px;
      border-radius: 8px;
}
#vendor-select-create_account{
  border-left:1px solid rgba(224, 236, 255, 1) !important;
  border-right: unset !important;
  border-top: 1px solid rgba(224, 236, 255, 1) !important;
  border-bottom: 1px solid rgba(224, 236, 255, 1) !important;
  font-size: 16px; 
      color: #4B4B4B;
       font-family:Gilroy,sans-serif;
        /* font-weight: 500; */
         box-shadow: "none";
       height: 50px;
      border-radius: 8px;
}
.Table_Design{
  border: 1px solid #DCDCDC;
  border-radius: 24px;
  /* width: 100%; */
  border-collapse: separate;
  border-spacing: 0;
  }
  .flatpickr-input .flatpickr-mobile {
    display: none !important;
  }





  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }