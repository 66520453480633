*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}


.custom-input:focus {
  box-shadow: none !important; 
  border-color: #ced4da !important; 
}

.lists {
  display: flex;
  padding-left: 25px;
}

.hoverList {
  list-style: circle;
  padding: 0;
  
}


/* .hoverList li {
  padding: 8px;
  transition: background-color 0.3s ease;
}

.hoverList li:hover {
  background-color: #F6F7FB;
  cursor: pointer;
  color: grey;
}

ul li{
  color: rgb(126, 125, 125);
  font-weight: 3px;
} */
#Bottom-border{
  width:40px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  border-radius: unset !important;
  font-size: 18px;
  color:black;
  font-weight: 500;
  text-align: center;
}



@media (max-width: 600px) {
    .Form{
      padding:20px;
          }
   
  }
  @media (max-width: 390px) {
    #Welcome{
        padding-top:50px !important;
    }
  }