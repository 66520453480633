.modal-dialog-centered{
    margin-left: 500px;
}
@media only screen and (max-width: 600px) and (min-width: 300px) {
    .modal-dialog-centered{
        margin-left: 10px;
    } 
}
@media only screen and (max-width: 1001px) and (min-width: 601px) {
    .modal-dialog-centered{
        margin-left: 70px;
    } 
}



.table-container {
    position: absolute;
    top: 0; /* Adjust to position it correctly */
    z-index: 100;
  }