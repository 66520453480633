.circulardes{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.circulardesone {
  display: flex;
  flex-direction: row;
}


.carddesign{
  margin-left: 0px;
  margin-top: 30px;
  padding-right: 20px;
  padding-left: 0px;

  

}

.spacedash{
  margin-left: -40px;
}
.texrtalii {
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin-left: 100px;
}

.status-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.received {
  background-color: green;
}

.receivable {
  background-color: gray;
}

.user{
  margin-left: 20px;
}
.crddesg{
  margin-left: 20px;
  margin-top: 10px;
  width: auto;
}




.complaints-container {
  width: 95%;
  /* max-width: 600px; */
  margin: 0 auto;
  background: #fff;
  border-radius: 20px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  padding: 15px;
  margin-top: 20px;
  border:1px solid #DCDCDC;
  margin-left: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.header h2 {
  margin: 0;
  font-size: 18px;
}

.header a {
  color: #007bff;
  text-decoration: none;
}

.complaint {
  display: flex;
  align-items: center;
  padding: 10px;
 
  /* padding-bottom: 30px; */
  /* border-top: 1px solid #f1f1f1; */
}

.complaint:first-child {
  border-top: none;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.complaint-info {
  flex: 1;
}

.name {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.details {
  margin: 0;
  font-size: 14px;
  color: #888;
}

.status {
  margin-right: 10px;
  /* padding: 3px; */
  border-radius: 70px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.status.pending {
  background: #ffd700;
  color: #fff;
}

.status.assigned {
  background: #28a745;
  color: #fff;
}

.room {
  font-size: 14px;
  color: #888;
}


/* Responsive styles */
@media (max-width: 768px) {
  .complaints-container {
    width: 100%;
    padding: 10px;
    margin-left: 0px;
  }

  .header h2 {
    font-size: 16px;
  }

  .header a {
    font-size: 14px;
  }

  .complaint {
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #f1f1f1;
    
  }

  .avatar {
    width: 30px;
    height: 30px;
  }

  .name {
    font-size: 14px;
  }

  .details {
    font-size: 12px;
  }

  .status {
    font-size: 10px;
  }

  .room {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .header {
    flex-direction: column;
    align-items: center;
  }

  .header h2 {
    font-size: 14px;
  }

  .header a {
    font-size: 12px;
  }

  .avatar {
    width: 25px;
    height: 25px;
    
  }

  .name {
    font-size: 12px;
  }

  .details {
    font-size: 10px;
  }

  .status {
    font-size: 8px;
  }

  .room {
    font-size: 10px;
  }
  .doughnut{
    margin-left: -25px;
  }
}




.expenses-container {
  width: 94%;
  
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  margin-left: 10px;
  margin-top: 10px;
  border:1px solid #DCDCDC;
}

.headertwo {
  text-align: start;
  margin-bottom: 20px;
}

.headertwo h2 {
  margin: 0;
  font-size: 18px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chart {
  position: relative;
  width: 50%;
  min-width: 200px;
  max-width: 300px;
  height: 200px;
  margin: 0 auto;

}

.center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  pointer-events: none;
}

.categories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 300px;
  margin-top: 20px;
  padding-left: 20px;
}

.category {
  display: flex;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
}

.text p {
  margin: 0;
  font-size: 14px;
}

.texxttt{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 28px;
}


.headerone{
  display: flex;
  align-items: center;
  justify-content:end;
  padding: 10px;
  background-color: #fff; /* You can adjust the background color */
}


.search-container {
  position: relative;
  width: 250px; 
  margin-left: 35px;
}

.search-input {
  width: 100%;
  padding: 8px 8px 8px 30px; /* Add left padding to make space for the icon */
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: url('../Assets//Images/search-normal.png') no-repeat center center;
  background-size: contain;
}



.notification-container {
  position: relative;
  margin-left: 10px;
}

.notification-icon {
  font-size: 24px;
  position: relative;
}

.notification-dot {
  position: absolute;
  top: 2px;
  right: 5px;
  height: 10px;
  width: 10px;
  background-color: blue; /* Color of the notification dot */
  border-radius: 50%;
}

.profile-container {
  margin-left: 10px;
}

.profile-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}



@media (max-width: 600px) {
  .content {
    align-items: center;
  }

  .chart, .categories {
    width: 100%;
  }
}


.dropp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
}

@media (max-width: 768px) {
  /* .crddesg {
    padding: 10px;
    margin-left: 50px;
    width: auto;
  } */
  .crddesg {
    margin-left: 0;
    padding: 1rem;
  }
  .dropp {
    flex-direction: column;
    align-items: start;
    padding-right: 10px;
    padding-left: 10px;
  }
  .dropdown {
    margin-top: 10px;
  }

  .chart-container {
    overflow-x: auto;
    white-space: nowrap;
  }

  .chart-wrapper {
    min-width: 900px; /* Ensure larger width for scroll */
  }
}


@media (max-width: 900px) {
  .circulardes{
    display: flex;
    flex-direction: column;
  }
  .circulardesone {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .texxttt{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .expenses-container {
    margin-left: 0px;
  }
}



@media (max-width: 576px) {
  .card-header span, .card-header p {
    font-size: 14px;
  }
  /* .recharts-surface{
    width: 230 !important;
  } */

  .list-group-item {
    flex-direction: column;
    align-items: flex-start;
  }
  .list-group-item img {
    width: 40px;
    height: 40px;
  }
  .list-group-item .fw-bold {
    font-size: 16px;
  }
  .list-group-item .text-muted {
    font-size: 14px;
  }
  .carddesign {
    /* margin-left: 0px;
    margin-top: 30px; */
    padding-right: 20px;
    padding-left: 100px;
    
   
}


}


.dashfirst{
  display: flex;
  flex-direction: row;
}
/* .dashtwo{
  display: flex;
  flex-direction: row;
  width: auto;
  margin-top: -20px;
  margin-left: 25px;
  
} */
.dashthree{
margin-left: -30px;
}
.eigthdesign{
  margin-left: 18px;
}
.dashfour{
 
margin-left: 10px;
width: 210px;
padding-top: 6px;
padding-bottom: 8px;

}
.spacecard{
  margin-left: -15px;
  width: 210px;
  margin-top: -15px;
}
.dashfive{
  width: 210px;
height: 70px;

}

/* .center-text{
  margin-left: -37px;
} */
.center-text {
  text-align: center;
}

.adjust-margin {
  margin-left: -37px !important;
}
.booking{
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.dashTable{
  border: 1px solid #DCDCDC;
  border-radius: 24px;
  /* width: 100%; */
  border-collapse: separate;
  border-spacing: 0;
  /* margin-left: -60px; */
  
  }
  .complaints-container{
    height: auto;
  }
  .firstcard{
    height: 170px;
    width: 150px;
    margin-top: -15px;
  }
  .secondcard{
    width: 210x;
    height: 80px;
    
  }
  .thirdcard{
    width: 210x;
    height: 80px;
  }
  .fourthcard{
    width: 210px;
    height: 80px;
   
  }
  .fifthcard{
    width: 210px;
    height: 80px;
  }
  .sixthcard{
    width: 210px;
    height: 80px;
  }
  .seventhcard{
    width: 210px;
    height: 80px; 
  }
  .eighthcard{
    height: 173px;
    width: 160px;
    padding-top: 8px;
    margin-top: 9px;
  }
  .chatwidth{
    width: 489px;
  }
 
  .dashtwo{
    display: flex;
    flex-direction: row;
    height:auto;
    width: 620px;
    margin-left: 30px;
    margin-top: -20px;
  }
  .invoicepending{
    margin-left: 30px;
  }
  .doughnut{
    margin-left: 0px;
  }
 
@media (max-width: 700px) {

  /* .recharts-surface{
    width: 230 !important;
  } */
  .invoicepending{
    margin-left: 0px;
  }
  .dashfirst{
    display: flex;
    flex-direction: column;
  }
  .chatwidth{
    width:auto;
  }

  .firstcard{
    height:auto;
    width: auto;
  }
  .spacecard{
    margin-left: 0px;
    width:auto;
  }
  .secondcard{
    width: auto;
    height:auto;
    
  }
  .eighthcard{
    height: auto;
    width:auto;
    /* padding-top: 8px; */
    margin-top: 9px;
  }
  .dashtwo{
    display: flex;
    flex-direction: column;
    height:auto;
    width: auto;
    margin-left: 0px;
    margin-top: 10px;

  }
  .dashthree{
    width:auto;
    height: auto;
    margin-left:0px;
    }
    .dashfour{
      width:auto;
    height:auto;
    margin-left:10px;
    }
    .dashfive{
      width:auto;
      height:auto;
      margin-left:10px; 
    }
    .spacedash{
      margin-top: 20px;
    }
    .booking{
      display: flex;
      flex-direction:column;
    }
    .complaints-container{
      height: auto;
    }
    .eigthdesign{
      margin-left: 0px;
    }
    .chatwidth{
      width: auto;
    }
    /* .center-text{
      margin-left: 0px;
    } */
}


.chart-container {
  overflow-x: hidden; /* Default: no scroll for large screens */
}

  @media only screen and (max-width: 900px) and (min-width: 700px) {
    .invoicepending{
      margin-left: 0px;
    }
    .dashfirst{
      display: flex;
      flex-direction: column;
    }
    .chatwidth{
      width:auto;
    }
  
    .firstcard{
      height:auto;
      width: auto;
    }
    .spacecard{
      margin-left: 0px;
      width:auto;
    }
    .secondcard{
      width: auto;
      height:auto;
      
    }
    .eighthcard{
      height: auto;
      width:auto;
      /* padding-top: 8px; */
      margin-top: 9px;
    }
    .dashtwo{
      display: flex;
      flex-direction: row;
      height:auto;
      width: auto;
      margin-left: 0px;
      margin-top: 10px;
  
    }
    .dashthree{
      width:auto;
      height: auto;
      margin-left:0px;
      }
      .dashfour{
        width:auto;
      height:auto;
      margin-left:10px;
      }
      .dashfive{
        width:auto;
        height:auto;
        margin-left:10px; 
      }
      .spacedash{
        margin-top: 20px;
      }
      .booking{
        display: flex;
        flex-direction:column;
      }
      .complaints-container{
        height: auto;
      }
      .eigthdesign{
        margin-left: 0px;
      }
      .chatwidth{
        width: auto;
      }
      /* .center-text{
  }
  
  
  @media only screen and (max-width: 1200px) and (min-width: 900px) {
    .dashfirst{
      display: flex;
      flex-direction: column;
      width: auto;
     
      
    }
    .firstcard{
      height:auto;
      width: auto;
    }
    .dashtwo{
      display: flex;
      flex-direction:row;
      height:auto;
      width:auto;
      margin-left: 0px;
      margin-top: 10px;
    }
    .firstcard{
      height: auto;
      width: auto;
      margin-top:10px;
    }
    .spacedash{
      margin-top: 10px;
      margin-left: 20px;
    }
    .spacecard{
      margin-left:20px;
      width: auto;
      height: auto;
      margin-top: 0px;
    }
   
  
  }
  
  
  @media only screen and (max-width: 1295px) and (min-width: 1200px) {
    .dashfirst{
      display: flex;
      flex-direction: row;
      width: auto;
     
      
    }
    /* .firstcard{
      height:auto;
      width: auto;
    } */
    .firstcard{
      height: 170px;
      width: auto;
      margin-top: 0px;
    }
    .dashtwo{
      display: flex;
      flex-direction:row;
      height:auto;
      width:auto;
      margin-left: 40px;
      margin-top: 10px;
    }
    
    .spacedash{
      margin-top: 0px;
    }
    /* .spacecard{
      margin-left:0;
      width: auto;
      height: auto;
      margin-top: 0px;
    } */
    .spacecard{
      margin-left: 10px;
      width: auto;
      margin-top: 10px;
      padding-left: 30px;
    }
    .eigthdesign{
      margin-left: 10px;
    }
    .eighthcard{
      height: 173px;
      width: 150px;
      padding-top: 8px;
      margin-top: 9px;
    }
   
  
  }



@media only screen and (max-width: 1200px) and (min-width: 900px) {
  .dashfirst{
    display: flex;
    flex-direction: column;
    width: auto;
   
    
  }
  .firstcard{
    height:auto;
    width: auto;
  }
  .dashtwo{
    display: flex;
    flex-direction:row;
    height:auto;
    width:auto;
    margin-left: 0px;
    margin-top: 10px;
  }
  .firstcard{
    height: auto;
    width: auto;
    margin-top:10px;
  }
  .spacedash{
    margin-top: 10px;
    margin-left: 20px;
  }
  .spacecard{
    margin-left:20px;
    width: auto;
    height: auto;
    margin-top: 0px;
  }
 

}


@media only screen and (max-width: 1295px) and (min-width: 1200px) {
  .dashfirst{
    display: flex;
    flex-direction: row;
    width: auto;
   
    
  }
  /* .firstcard{
    height:auto;
    width: auto;
  } */
  .firstcard{
    height: 170px;
    width: 140px;
    margin-top: 0px;
  }
  .dashtwo{
    display: flex;
    flex-direction:row;
    height:auto;
    width: 600px;
    margin-left: 40px;
    margin-top: -12px;
  }
  
  .spacedash{
    margin-top: 10px;
  }
  /* .spacecard{
    margin-left:0;
    width: auto;
    height: auto;
    margin-top: 0px;
  } */
  .spacecard{
    margin-left: -10px;
    width: 210px;
    margin-top: -12px;
  }
  .eigthdesign{
    margin-left: 10px;
  }
  .eighthcard{
    height: 173px;
    width: 150px;
    padding-top: 8px;
    margin-top: 9px;
  }
 

}