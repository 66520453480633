/* ::-webkit-scrollbar {
    display: none;
} */




 /* * {
  scrollbar-width: none;
}  */

 ::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.react-datepicker__header {
  text-align: center;
  background-color: #FFF !important;
  border-bottom: unset !important;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker {
  font-family: 'Gilroy' !important;
  font-size: 0.8rem;
  border: 1px solid rgb(217, 217, 217) !important;
}

 @font-face {
    font-family: 'Gilroy';
    src: url('/src/Assets/Font/Gilroy-ExtraBold.otf') format('truetype');
           font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('/src/Assets/Font/Gilroy-Light_0.ttf') format('truetype');
           font-weight: 300;
    font-style: normal;
  }
 
  /* semi bold - 600 */

  @font-face {
    font-family: 'Gilroy';
    src: url('/src/Assets/Font/Gilroy-SemiBold_0.ttf') format('truetype');
           font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('/src/Assets/Font/Gilroy-Medium_0.ttf') format('truetype');
           font-weight: 500;
    font-style: normal;
  }

  @font-face {
       font-family: 'Gilroy';
       src: url('/src/Assets/Font/Gilroy-Black_0.ttf') format('truetype');
              font-weight: 900;
       font-style: normal;
     }
  /* @font-face {
       font-family: 'Satoshi';
       src: url('/src/Assets/Font/Satoshi-Medium_0.ttf') format('truetype');
              font-weight: 500;
       font-style: normal;
     } */


 @font-face {
    font-family: 'Montserrat';
    src: url('/src/Assets/Fonts/Montserrat-SemiBold.otf') format('truetype');
           font-weight: 600;
    font-style: normal;
  } 

  @font-face {
       font-family: 'Montserrat';
       src: url('/src/Assets/Fonts/Montserrat-Medium.otf') format('truetype');
              font-weight: 500;
       font-style: normal;
     } 
     @font-face {
       font-family: 'Montserrat';
       src: url('/src/Assets/Fonts/Montserrat-Light.otf') format('truetype');
              font-weight: 400;
       font-style: normal;
     } 
     /* @font-face {
       font-family: 'Outfit';
       src: url('/src/Assets/Fonts/Outfit.ttf') format('truetype');
       font-weight: 300;
       font-style: normal;
   } */

   /* .form-control:focus {
       color: #000 !important;
       background-color: #fff;
       border-color: #86b7fe;
       font-weight :900px !important;
       outline: 0;
       box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
     } */


     .fade-in{
       opacity: 0;
            }
     .fade-in.appear{
       opacity: 1;
       animation-duration: 1s;
       animation-name: fadeInBottom;
     }
     @keyframes fadeInBottom {
       from {
           opacity: 0;
           transform: translateY(100%);
           
       }
       to { opacity: 1 }
     }
     


     .animated-text {
       font-size: 24px;
       animation: slide-in 1s ease forwards; 
       opacity: 0; 
     }
     
     @keyframes slide-in {
       0% {
         transform: translateY(100%);
         opacity: 0;
       }
       100% {
         transform: translateY(0);
         opacity: 1;
       }
     }



     @font-face {
      font-family: 'Kalam';
      src: url('/src/Assets/kalamFont/Kalam-Bold.ttf') format('truetype');
      font-weight: 700;
      font-style: normal;
    }
    @font-face {
      font-family: 'Kalam';
      src: url('/src/Assets/kalamFont/Kalam-Light.ttf') format('truetype');
      font-weight: 300;
      font-style: normal;
    }
    
    @font-face {
      font-family: 'Kalam';
      src: url('/src/Assets/kalamFont/Kalam-Regular.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
    }