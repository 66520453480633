.flatpickr-calendar.animate.open {
  top: 70px !important;
  left: 310px !important;
  right: auto;
}


#custom-flatpickr-1  .flatpickr-calendar.animate.open{
  top: 500px !important;
  left: 310px !important;
}



.list-group-item {
    width:250px !important;
}
.list-group-item:hover {
    background-color:  #1E45E1; 
    color: #fff; 
  }
  
  .list-group-item.active {
    /* background-color: #E7F1FF;  */
    color: #fff; 
  }


  .list-group-item .sub_item {
    width:200px !important;
    /* background-color: #E7F1FF;  */
    color: #000; 
  }

  .list-group-item .sub_item:hover {
    background-color:  #1E45E1 !important; 
    color: #fff; 
  }
  .list-group-item .sub_item:active {   
    background-color: #1E45E1 !important; 
  }

 


 .list-group {
  --bs-list-group-color: var(--bs-body-color);
  /* --bs-list-group-bg: #E7F1FF !important; */
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg:  #1E45E1 !important;
  --bs-list-group-active-border-color: #1E45E1 !important;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.react-datepicker-wrapper {
  width:100% !important;

}
.custom-modal .modal-content {
  border-radius: 10px !important;
  
}


/* show-scroll-category */


.show-scroll-category::-webkit-scrollbar {
  width: 8px !important;
  
}
 
.show-scroll-category::-webkit-scrollbar-track {
  background: #dcdcdc !important;
  /* border-radius: 8px !important; */
 
}
 
.show-scroll-category::-webkit-scrollbar-thumb {
  background: #bcd4f5 !important;
  border: 3px solid #bcd4f5 !important;
   /* border-radius: 8px; */
  min-height: 20px !important;
}