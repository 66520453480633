.show-scrolls::-webkit-scrollbar {
    width: 1px !important;
    min-width: 1px;
  }
  
  .show-scrolls::-webkit-scrollbar-track {
    background: #E0E0E0 !important;
    border-radius: 8px !important;
  }
  
  .show-scrolls::-webkit-scrollbar-thumb {
    background: rgb(200, 220, 250) !important;
    border-radius: 8px;
  }
  
  .show-scrolls {
    scrollbar-width: thin;
    scrollbar-color: rgb(200, 220, 250) transparent; /* Thumb and track color */
    overflow-y: auto; /* Ensure scrollbar is visible */
  }

  .custom-modal .modal-content {
    border-radius: 20px;
 
  }
  