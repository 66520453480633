
.list-Item.active {
  background-color:  #FFFFFF!important; 
  color: rgba(30, 69, 225, 1)!important; 
}


.list-Items.active {
  background-color:  #FFFFFF!important; 
  color: rgba(30, 69, 225, 1)!important; 
}


.sidebar::-webkit-scrollbar {
  display: none !important;
}
.main-content::-webkit-scrollbar {
  display: none !important;
}
.container {
  width: 100%;
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  background-color: #FFFFFF;
}

#exampleInput {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom: 1px solid lightgray;
  font-weight: 700;
}

#exampleInput {
  box-shadow: none;
}

#exampleSelect {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom: 1px solid lightgray;
  font-weight: 700;
}

#exampleSelect {
  box-shadow: none;
}
#hostel-image{
position:relative;
}
#plus-image{
position:absolute;
margin-top:38px;
}
.list-Item{
 
color: #4B4B4B !important;
padding:8px 16px;
max-width:200px !important;
width:100%;
gap:10px !important;
border-radius: 6px;
margin-bottom: 2px;
}
.list-Button{
  color: #FFFFFF !important;
padding:8px 16px;
max-width:200px !important;
width:100%;
gap:10px !important;
border-radius: 6px;
margin-bottom: 2px;
background-color: #1E45E1 !important;
}

.list-Button:hover{
  background-color: #FFFFFF !important;
  color:gray !important;
}

.list-Item:hover{
  background-color: #FFFFFF !important;
  color:gray !important;
}

.menu-icon:hover img{
 
  color:white !important;
}


.list-Items{
  color: #4B4B4B !important;
padding:5px 16px;
max-width:200px !important;
width:100%;
border-radius: 6px;
}
.list-Items:hover{
  background-color: #FFFFFF !important;
  color:gray !important;
}
/* .container{
  margin-right: unset !important;
    margin-left: unset !important;

} */

.list-sub-Item{
  color: #4B4B4B !important;
  padding:8px 16px;
  max-width:180px !important;
  width:100%;
  gap:10px !important;
  border-radius: 6px;
  margin-bottom: 2px;
}


.list-sub-Item:hover{
  background-color: #FFFFFF !important;
  color:gray !important;
}

.list-sub-Item.active {
  background-color:  #FFFFFF!important; 
  color: rgba(30, 69, 225, 1)!important; 
}

.show-scrolls::-webkit-scrollbar {
  width: 1px !important;

}

.show-scrolls::-webkit-scrollbar-track {
  background: #E0E0E0 !important;
  border-radius: 8px !important;
}

.show-scrolls::-webkit-scrollbar-thumb {
  background: rgb(200, 220, 250) !important;
  border-radius: 8px;
}

.show-scrolls {
  scrollbar-width: thin;
  scrollbar-color: rgb(200, 220, 250) transparent; /* Thumb and track color */
  overflow-y: auto; /* Ensure scrollbar is visible */
}


@media (max-width: 768px) {
  .Title{
    display: none !important;
  }
  .list-Item{
    display: flex !important;
    justify-content: center !important
  }
}



/* @media (max-width:650px) {
  .container {
    display: flex;
    flex-direction: column;
  }
} */

@media (max-width: 390px) {
  .Title {
    display: none !important;
  }
  .toggleButton{
    display:none !important;
  }
  .list-Item{
    display: flex !important;
    justify-content: center !important;
    padding:0 px !important;
  }
}
@media (max-width: 200px) {
  .Title {
    display: none !important;
  }
  .toggleButton{
    display:none !important;
  }
  .list-Item{
    display: flex !important;
    justify-content: center !important;
    padding:0 px !important;
  }
}