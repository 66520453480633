.css-117w1su-MuiStepIcon-text {
    fill: unset !important;
    font-size: 0.75rem;
    font-family:unset !important;
}
/* .MuiStepContent-root {
    border-left: 2px dotted rgba(220, 220, 220, 1);
    padding-left: 30px;
    margin-left: 10px;
} */
.css-14yr603-MuiStepContent-root {
    margin-left: 12px;
    padding-left: 20px;
    padding-right: 8px;
    height:50px;
    border-left: 2px dashed rgba(220, 220, 220, 1) !important;
}
  .MuiStepConnector-root {
    display: none;
  }
  .css-1mz1l2x-MuiStepContent-last{
   display:none;
  }

  .css-1hv8oq8-MuiStepLabel-label {
    font-family: "Gilroy" !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color:rgba(0, 0, 0, 1);
    display: block;
    -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
button:focus:not(:focus-visible) {
  outline: unset !important;
}
.accordion-button:not(.collapsed) {
  color: unset !important; 
   background-color: unset !important;
  box-shadow: unset !important;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: unset !important;
}