
.Nav-Links{
    color: #000000 !important;
   
    
}
.Nav-LinksUnActive{
    color:#939393 !important;
   
}
.ActiveNumberFloor{
    color:rgba(30, 69, 225, 1) !important;
}
.UnActiveNumberFloor{
    color:rgba(34, 34, 34, 1) !important;
}
.ActiveFloortext{
    color:rgba(34, 34, 34, 1) !important;
}
.UnActiveFloortext{
    color: rgba(156, 156, 156, 1) !important;
}

.show-scrolls::-webkit-scrollbar {
    width: 8px !important;
    
  }
   
  .show-scrolls::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 8px !important;
   
  }
   
  .show-scrolls::-webkit-scrollbar-thumb {
    background: #E0E0E0 !important;
    border: 3px solid #E0E0E0 !important;
     border-radius: 8px;
    max-height: 5px !important;
  }
  




/* .Paying-Guest .nav-link {
    display: block;
    padding: unset !important;
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    text-decoration: none;
    background: 0 0;
    border: 0;

    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
} */


.hover-hostel-name:hover{
text-decoration: underline;
color:#1E45E1 !important;
cursor:pointer;


}

.Navs-Item{
    border: 2px solid  #dcdcdc !important;
    
}
.active-floor {
    border: 2px solid  #1E45E1 !important;
    
  }

.pagination {
    --bs-pagination-padding-x: 0.85rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 18px !important;
    --bs-pagination-color: #000 !important;
    --bs-pagination-bg: var(--bs-body-bg);
    --bs-pagination-border-width: var(--bs-border-width);
    --bs-pagination-border-color: white !important;
    --bs-pagination-border-radius: var(--bs-border-radius);
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: unset !important;
    --bs-pagination-hover-border-color: var(--bs-border-color);
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: unset !important;
    --bs-pagination-focus-box-shadow: unset !important;
       --bs-pagination-active-color: #0d6efd !important;
    --bs-pagination-active-bg: unset !important;
    --bs-pagination-active-border-color: #dcdcdc !important;
       --bs-pagination-disabled-color: var(--bs-secondary-color);
    --bs-pagination-disabled-bg: var(--bs-secondary-bg);
    --bs-pagination-disabled-border-color: unset !important;
    display: flex;
    padding-left: 0;
    list-style: none;
}

.disabled>.page-link, .page-link.disabled {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: unset !important;
    border-color: #dcdcdc !important;
    
}


.page-link {
    position: relative;
    display: block;
    visibility: visible;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: var(--bs-pagination-color);
    text-decoration: none;
    background-color: var(--bs-pagination-bg);
    border: #dcdcdc !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


.active>.page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border: 1px solid #dcdcdc !important;
    border-radius: 5px !important;
}

.custom-modal-width .modal-dialog {
    max-width: 600px; 
    width: 100%;
  }
  
  .custom-modal-width-vendor .modal-dialog {
    max-width: 550px; 
    width: 100%;
  }
  .custom-modal-width-Amenities .modal-dialog {
    max-width: 700px !important; 
    width: 100%;
    max-height: 700px !important;
    height: 100%;
  }



  .vertical-line {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    border-left: 2px solid #000; /* Vertical line */
    height: 100%; /* Adjust height as per your layout */
    padding-left: 0px;
  }
  
  .vertical-line::after {
    content: "Services"; /* The text at the end of the line */
    position: absolute;
    transform: rotate(-90deg);
    bottom: 0; /* Position the text at the bottom of the line */
    left: -40px; /* Adjust this based on how far you want the text from the line */
    background-color: #fff; /* Optional: background color to make the text stand out */
    padding: 0 5px;
    font-weight: bold;
    font-size: 16px;
    color: #0047FF; /* Blue color for text */
  }
  