.texxttt{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 28px;
  }
  
 

.table-scrollbar::-webkit-scrollbar {
  display: flex !important; 
}
/* .table-cells {
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 150px;
} */


.table-scrollbar {
  scrollbar-width: auto;   
  -ms-overflow-style: auto; 
}


  .headerone{
    display: flex;
    align-items: center;
    justify-content:end;
    padding: 10px;
    background-color: #fff; /* You can adjust the background color */
  }
  
  
  .search-container {
    position: relative;
    width: 250px; 
    margin-left: 35px;
  }
  
  .search-input {
    width: 100%;
    padding: 8px 8px 8px 30px; /* Add left padding to make space for the icon */
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
  }
  
  .search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background: url('../Assets//Images/search-normal.png') no-repeat center center;
    background-size: contain;
  }
  
  
  
  .notification-container {
    position: relative;
    margin-left: 10px;
  }
  
  .notification-icon {
    font-size: 24px;
    position: relative;
  }
  
  .notification-dot {
    position: absolute;
    top: 2px;
    right: 5px;
    height: 10px;
    width: 10px;
    background-color: blue; /* Color of the notification dot */
    border-radius: 50%;
  }
  
  .profile-container {
    margin-left: 10px;
  }
  
  .profile-image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }



.custom-table {
    border: 1px solid #DCDCDC;
    border-radius: 24px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
    
  }
  
  
  .custom-table td {
    border: none;
  }
  
  .custom-checkbox {
    color: #DCDCDC;
    border-radius: 30px;
    accent-color: red;
  }
  
  .custom-checkbox:checked {
    background-color: red;
  }
  
.Table-header tr th {
color: #939393;
font-family: Gilroy;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}


/* .table>:not(caption)>*>* {
    padding: 1.2rem .5rem !important;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
} */
.Invoice_Name:hover{
  text-decoration: underline !important;
  color:#1E45E1 !important;

}

/* ::-webkit-scrollbar {
  width: 6px !important; */
  /* min-height:3px !important;
  max-height: 6px !important; */
  /* height:40px !important; */
  /* background-color: #fff;
  border-radius: 8px !important;
  background-image: linear-gradient(180deg, #e0e0e0 0%, #e0e0e0 99%); */
  /* box-shadow: inset 2px 2px 5px 0 rgba(red, 0.5); */
  /* border-radius: 100px;

} */



.show-scroll::-webkit-scrollbar {
  width: 8px !important;
  
}
 
.show-scroll::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 8px !important;
 
}
 
.show-scroll::-webkit-scrollbar-thumb {
  background: #E0E0E0 !important;
  border: 3px solid #E0E0E0 !important;
   border-radius: 8px;
  max-height: 5px !important;
}



.show-scrolls::-webkit-scrollbar {
  width: 8px !important;
  
}
 
.show-scrolls::-webkit-scrollbar-track {
  background: #fff !important;
  border-radius: 8px !important;
 
}
 
.show-scrolls::-webkit-scrollbar-thumb {
  background: #bcd4f5 !important;
  border: 3px solid #bcd4f5 !important;
   border-radius: 8px;
  min-height: 20px !important;
}


@media (max-width: 767px) {
  /* Hide scrollbar on small and extra-small screens */
  .show-scroll {
      overflow-y: hidden;
  }
}
.totalamount{
margin-left: 200px;
}
@media only screen and (max-width: 900px) and (min-width: 300px) {
  .totalamount{
    margin-left:0px;
    }
}