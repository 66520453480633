.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
.roomdetailscard{
  display: flex;
  flex-direction: row;
}
.Customer_Name_Hover:hover{

text-decoration: underline;

}
.customerfilling{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0px;
  margin-top: 20px;
}
.cardalignitems{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttons{
  margin-right: 20px;
}

.Table_Design_user{
  border: 1px solid #DCDCDC;
  border-radius: 24px;
  /* width: 100%; */
  border-collapse: separate;
  border-spacing: 0;
  }
.custom-table thead {
  background-color: #F6F7FB;
  font-size: 14px;
  color: #91969E;
}

.custom-table th, .custom-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.custom-table th {
  text-align: left;
}

.custom-table tbody tr {
  font-weight: 700;
}

.custom-table tbody tr td {
  color: black;
}

.custom-table tbody tr td.name {
  color: #0D99FF;
  text-decoration: underline;
  cursor: pointer;
}

.custom-table tbody tr td.assign-bed {
  color: #0D99FF;
  text-decoration: underline;
  cursor: pointer;
}


/* roomtetail */

.tapppinfour{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tab-item {
  padding: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}


/* .tab-item.active {
  text-decoration: underline;
  text-decoration-color: blue;
  
  
} */
.tab-item.active {
  text-decoration: underline;

  text-decoration-color: #1E45E1;
  text-underline-offset: 15px; /* Adjust this value as needed */
  text-decoration-thickness: 2px;
  
}

 /* overdue */

 .overdue{
  display: flex;
  flex-direction: row;
 

 }
 .additional{
  margin-top:"-100px" !important;
 }
 .modal-dialog-bx{
  margin-left: 500px;
 }
 @media (max-width:800px) {
  .additional{
    margin-top:"0px";
   }
   .modal-dialog-bx{
    margin-left: 0px;
   }
   .roomdetailscard{
    display: flex;
    flex-direction: column;
  }
  .tapppinfour{
    display: flex;
    flex-direction: column;
   
  }
  .tab-item {
    padding: 2px;
    cursor: pointer;
  }
  /* .overdue{
    display: flex;
    flex-direction: column;
  
   } */
   .cardalignitems{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }
 }


 /* amni */
 .spantag{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

 }
 .ebtable{
  border: 1px solid #DCDCDC;
  border-radius: 24px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
 }
 
 .ebtable td{
  border: none;
 }

.customerprofile{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-right: 12px;
  
}
.customer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 19px;
  margin-top: -25px;
}
.customerfill {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.cuslable{
  margin-left: 0px;
}

.searchGroup{
  margin-left: 0px;
}
@media only screen and (max-width: 500px) and (min-width: 300px) {
  
 
  .searchGroup {
    margin-left: 0; /* Adjust to prevent overflow */
  }
}


.toast-center-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}



.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 8px !important;
  height: 8px !important;
  padding: 0;
  margin-right: 3px;
  margin-top: 30px !important;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color:#1E45E1 !important;
  background-clip: padding-box;
  border: 0;
  border-top: 40px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
  border-radius: 50% !important;
  bottom: -30px !important;
  /* position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10; */

}